import {
  EQuoteCardBackground,
  QuoteCard,
  QuoteCardWithAvatar,
  toImageProps,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TQuoteCardConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'quote-card' }
>;

const QuoteCardConnected: React.FC<TQuoteCardConnectedProps> = props => {
  const {
    authorTitle,
    authorPosition,
    banner,
    description,
    theme,
    authorAvatar,
  } = props;

  if (theme === EQuoteCardBackground.BlockquoteWithAvatar)
    return (
      <QuoteCardWithAvatar
        description={description}
        authorTitle={authorTitle}
        authorPosition={authorPosition}
        authorAvatar={authorAvatar}
      />
    );

  return (
    <QuoteCard
      description={description}
      authorTitle={authorTitle}
      authorPosition={authorPosition}
      authorAvatar={authorAvatar}
      banner={toImageProps(banner, {
        useFill: true,
        tabletVW: '20vw',
        desktopVW: '20vw',
      })}
      theme={theme as EQuoteCardBackground}
    />
  );
};

export default QuoteCardConnected;
